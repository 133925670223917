.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    position: fixed;
    top: 0;
    width: 100%;
    background: $color-white;
    z-index: 10;
    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: $spacer-4;
    }
    &__animation {
        width: 35px;
        height: 35px;
        border: 5px solid rgba(189, 189, 189, 0.25);
        border-left-color: $color-crimson;
        border-top-color: $color-crimson;
        border-radius: 50%;
        display: inline-block;
        animation: rotate 600ms infinite linear;
    }
}

@keyframes rotate {
    to {
        transform: rotate(1turn);
    }
}
