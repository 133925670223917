@keyframes linkarrow {
    from {
        left: 0rem;
    }
    50% {
        left: 0.5rem;
    }
    95% {
        left: -0.2rem;
    }
    to {
        left: 0rem;
    }
}
