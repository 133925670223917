.page404 {
    &__heading h1,
    &__heading {
        text-align: center;
        font-size: $font-size-mobile-h1-404;
        line-height: $line-height-mobile-h1-404;
        @include nlc {
            margin-bottom: $spacer-8;
        }
        @include md {
            font-size: $font-size-h1-404;
            line-height: $line-height-h1-404;
            @include nlc {
                margin-bottom: $spacer-16;
            }
        }
    }
    &__description {
        p {
            font-size: $font-size-lead;
            line-height: $line-height-h4;
        }
    }
    .form__group {
        margin: $spacer-8 0;
    }
}
