.gallery {
    &__top {
        margin-bottom: $spacer-4;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    &__top-title {
        margin-bottom: 0 !important;
        margin-right: auto;
        display: none;
        @include md {
            display: block;
        }
    }
    &__title {
        @include nlc {
            margin-bottom: $spacer-6;
        }
    }
    &__count {
        margin-right: $spacer-4;
        flex-shrink: 0;
    }
    &__controls {
        display: flex;
    }
    &__control {
        @extend .btn-tertiary;
        border-radius: $spacer-1;
        background-repeat: no-repeat;
        background-position: center;
        top: $spacer-4;
        width: 2.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2.5rem;
        cursor: pointer;
        @include nlc {
            margin-right: $spacer-1;
        }
        &:active {
            transform: scale(0.95);
        }
    }
    &__main {
        border: 1px solid $color-grey-10;
        &--expand {
            .swiper-wrapper {
                display: block;
                height: auto !important;
            }
        }
    }
    .swiper-slide-active {
        z-index: 3 !important;
    }
    .testimonial {
        border: none;
        margin: 0;
        padding-left: 0.8rem;
    }
    .richtext ul li {
        line-height: $line-height-body;
    }
    &__thumbs {
        margin-top: $spacer-2;
        display: none !important;
        @include md {
            display: block !important;
        }
        .swiper-slide-thumb-active .gallery__thumb-wrapper {
            border: 4px solid $color-crimson;
        }
        .swiper-slide-thumb-active .gallery__thumb {
            border: 1px solid $color-crimson !important;
            opacity: 1;
        }
        .swiper-slide-thumb-active .gallery__caption {
            margin-top: 8px;
        }
        .gallery__thumb-wrapper--video {
            position: relative;
        }
    }
    &__thumb-wrapper {
        position: relative;
        cursor: pointer;
        aspect-ratio: 186/124;
        width: 100%;
        border: 4px solid transparent;
    }
    &__thumb-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        color: $color-white;
        font-size: $spacer-16;
        cursor: pointer;
        .fa-primary {
            color: $color-slate;
            fill: $color-slate;
        }
        .fa-secondary {
            color: $color-white;
            fill: $color-white;
            opacity: 1;
        }
    }
    &__thumb {
        opacity: 0.7;
        border: 1px solid $color-grey-10 !important;
    }
    &__caption {
        margin-left: $spacer-1;
        margin-top: $spacer-2;
        color: $color-black;
        font-size: $font-size-small;
        line-height: $line-height-small;
        &--center {
            text-align: center;
        }
    }
    &--1a & {
        &__main--expand {
            .swiper-wrapper {
                display: block;
            }
            .gallery__sidebar {
                max-height: none !important;
            }
        }

        &__slide {
            display: flex;
            flex-direction: column;
            overflow-x: hidden;
            @include lg {
                flex-direction: row-reverse;
            }
        }

        &__media-container {
            flex: 1;
            .video__image {
                aspect-ratio: 16/9;
                @include md {
                    aspect-ratio: 16/12;
                }
            }
        }
        &__media {
            order: 1;
            aspect-ratio: 16/9;
            @include md {
                aspect-ratio: 16/12;
            }
            @include lg {
                order: 2;
            }
        }
        &__sidebar {
            order: 2;
            background-color: $color-white;
            display: none;
            border-right: 1px solid $color-grey-10;
            @include md {
                padding: 2.25rem 0;
                overflow-y: auto;
            }
            @include lg {
                display: block;
                padding: 2.25rem;
                order: 1;
                width: 23.75rem;
            }
        }
        &__mobile-description {
            display: block;
            padding-top: $spacer-6;
            @include lg {
                display: none;
            }
        }
    }
    &--1b & {
        &__media {
            aspect-ratio: 16/9;
            width: 100%;
        }
        &__mobile-description {
            display: block;
            padding-top: $spacer-6;
            @include md {
                display: none;
            }
        }
    }
    &--1c & {
        &__media {
            aspect-ratio: 16/9;
        }
    }
    &--1d & {
        &__main {
            border: none;
        }
        &__media {
            aspect-ratio: 16/9;
        }
    }
    &--2a & {
        &__prev,
        &__next {
            @include md {
                margin-top: 0;
                top: 50%;
                transform: translateY(-50%);
                &:active {
                    transform: translateY(-50%);
                }
            }
        }
        &__prev {
            left: $spacer-4;
        }
        &__media {
            aspect-ratio: 16/9;
            width: inherit;
            @include lg {
                max-width: inherit;
            }
        }
        &__main {
            border: none;
        }
        &__slide {
            border: 1px solid $color-grey-10;
        }
    }
    &--3a & {
        &__media {
            aspect-ratio: 16/9;
            border: 1px solid $color-grey-10;
        }
    }
}
