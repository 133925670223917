.dropdown {
    position: relative;
    max-width: 100%;
    @include md {
        max-width: 18.75rem;
    }
    &__trigger {
        height: $spacer-12;
        width: 100%;
        padding: 0 0.875rem;
        cursor: pointer;
        font-size: $font-size-body;
        color: $color-grey-80;
        border-radius: $input-border-radius;
        border: 1px solid $color-grey-60;
        background: $color-white;
        transition: $transition-default;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include focus;
        &:hover {
            background: $color-grey-5;
        }
    }
    &__arrow {
        line-height: 0;
    }
    &__menu {
        position: absolute;
        width: 100%;
        background: $color-white;
        margin-top: $spacer-1;
        z-index: 2;
        @include elevation-far;
        max-height: 80vh;
        overflow-y: auto;
    }
    &__item {
        padding: 0 0.875rem;
        height: $spacer-12;
        color: $color-grey-80;
        display: flex;
        align-items: center;
        text-decoration: none;
        cursor: pointer;
        &:hover {
            background: $color-grey-5;
        }
        &:focus-visible {
            outline: none;
            box-shadow: none;
            background: $color-grey-5;
        }
    }
    &__icon {
        margin-right: $spacer-2;
        color: $color-grey-40;
    }
    &__selection {
        display: flex;
        align-items: center;
    }
    &--tabs {
        display: block;
        max-width: none;
        .dropdown__trigger {
            height: $spacer-16;
            border: none;
            border-left: solid $spacer-1 var(--tab-glider-color);
            border-radius: 0;
            color: $color-black;
            &:hover {
                background: $color-white;
            }
        }
        .dropdown__arrow {
            color: var(--tab-glider-color);
        }
        .dropdown__icon {
            font-size: $spacer-8;
            margin-right: $spacer-2;
            width: $spacer-10;
            color: $color-black;
        }
        .dropdown__item {
            height: $spacer-16;
        }
        @include md {
            display: none;
        }
    }
}
