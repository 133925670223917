img {
    max-width: 100%;
    height: auto;
}

.fluid {
    max-width: 100%;
    height: auto;
    width: 100%;
    display: flex;
}

.native {
    max-width: 100%;
    width: auto;
}

.circle {
    border-radius: 50%;
}

.image {
    position: relative;
    line-height: 0;
    &--mobile {
        @include md {
            display: none !important;
        }
    }
    &--tablet {
        @media (max-width: 767px) {
            display: none !important;
        }
        @include lg {
            display: none !important;
        }
    }
    &--desktop {
        @media (max-width: 991px) {
            display: none !important;
        }
    }
    &--vertical {
        display: grid;
        height: 100%;
        align-content: center;
    }
    &--intrinsic {
        &.image--vertical {
            justify-content: start;
        }
        &.image--horizontal {
            display: grid;
            justify-content: center;
        }
    }
    &--closest {
        @include elevation-closest;
    }
    &--close {
        @include elevation-close;
    }
    &--far {
        @include elevation-far;
    }
    &--farthest {
        @include elevation-farthest;
    }
    &--horizontal {
        display: grid;
        justify-self: center;
    }
}

.full-size {
    width: 100%;
    height: 100%;
}

.offset {
    overflow-x: hidden;
    &__media {
        &--4 {
            width: 49vw;
        }
        &--5 {
            width: 44vw;
        }
    }
}
